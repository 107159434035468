<template>
  <div class='view'>
    <top-nav @menu_data="getMenuData" />
    <div class="blank70"></div>
    <div class="about_us">
      <div class="about_us_mission">
        <p class="about_us_mission_title">Mission Statement</p>
        <p class="about_us_mission_content">To connect all professionals involved in the project marketing field within the real estate industry, through a simplified, multi-faceted platform, creating seamless integrated workflows.
        </p>
        <div class="quote_img">
          <img src="@/assets/imgs/pc/aboutus/quote_img.png" alt="">
        </div>
      </div>
      <div class="about_us_content">
        <div class="about_us_content_inner">
          <p class="content_title">About Us</p>
          <p class="content_info">Arcanite is a comprehensive project marketing software solution for the real estate sector. Designed to aggregate all aspects of the project marketing process from a single centralised platform, Arcanite's unique interface provides a unified space for all property professionals.</p>
          <p class="content_info">New property development sales are typically distributed through multiple agencies, channels, and sales platforms, with each group utilising varying software for tracking sales, reporting, and managing teams.</p>
          <p class="content_info">Arcanite's automated system provides seamless connectivity between a multitude of users, allowing developers, agents and their professional associates to view all information for multiple developments and their project marketing initiatives, in real time.</p>
          <p class="content_info">The multi-faceted platform streamlines stock distribution, sales management, administrative tasks, live updates, customer profiles, lead generation, file sharing, and much more, automating all workflows from the convenience of a mobile device.</p>
          <p class="content_info">Additionally, Arcanite facilitates the aggregation of external systems, with the capability to sync with all major CRM programs such as Salesforce, Hubspot and Zapier, and integrate industry relevant systems such as REA, Domain and Mailchimp.</p>
          <p class="content_info">Arcanite users can manage all workflows from the one platform and enjoy substantial savings of time and money.</p>
          <p class="content_info">For more information, or to book a demo, simply click the button below.</p>
          <div class="content_info_btn" @click="showPopupWidget">
            Book A Demo
          </div> 
        </div>
      </div>
      <div class="part2RecoginizedIcons">
        <div class="part2-topcontent">
          <trusted></trusted>
        </div>
        <div class="part2-midcontent">
          <featuredIn></featuredIn>
        </div>
        <div class="part2-bottomcontent">
            <recognised></recognised>
        </div>
      </div>
      <!-- <div class="trusted_by">
        <div class="trusted_by_title">Trusted By</div>
        <div class="trusted_by_icon_content">
          <el-carousel :interval="10000" height="66px" indicator-position="outside">
            <el-carousel-item v-for="(company_collect,j) in companylist" :key="j">
              <div class="carou_item_clients">
                <span class="carou_item_client_logo" v-for="(d,i) in company_collect" :key="i">
                  <img :src="d.company_logo" :class="{'height_rely_img':d.company_name=='Foye','width_rely_img':d.company_name!='Foye'}" alt="">
                </span>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div> -->
    </div>
    <footer-nav />
    <menu-bar :isShow="isShow" @changeIsShow="changeShow" />
  </div>
</template>

<script>
export default {
  components: {
    "top-nav": resolve => require(["./TopNav.vue"], resolve),
    "footer-nav": resolve => require(["./FooterNav.vue"], resolve),
    "menu-bar": resolve => require(["./MenuBar.vue"], resolve),
    "trusted":(resolve) => require(["./Trusted.vue"], resolve),
    "recognised":(resolve) => require(["./Recognised.vue"], resolve),
    "featuredIn": (resolve) => require(["./FeaturedIn.vue"], resolve),
  },
  created(){
    if(localStorage.getItem('country')=="id"){
      this.isID=true;
    }else{
      this.isID=false;
    }
  },
  data() {
    return {
      isID:false,
      isShow: false,
    };
  },
  methods: {
    changeShow() {
      this.isShow = false;
    },
    getMenuData(val) {
      val == "1" ? (this.isShow = true) : (this.isShow = false);
    },
    showPopupWidget() {
      if(!this.isID){
        Calendly.showPopupWidget('https://meetings.hubspot.com/william463?embed=true');
      }else{
        Calendly.showPopupWidget('https://calendly.com/erni-yap');
      }
      return false;
    }
  },
}
</script>
<style lang='scss' scoped>
.view {
  background-color: #f8f8f8;
  .blank70 {
    height: 70px;
  }
  .about_us {
    // border: 1px solid red;
    // height: 260px;
    background: transparent url("../../assets/imgs/app/aboutus/bg_img.png")
      no-repeat center 0;
    // background-position-y: 50px;
    background-size: 100%;
    .about_us_mission {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      padding-top: 46px;
      text-align: center;
      .about_us_mission_title {
        font-family: Poppins-Bold;
        font-size: 26px;
        // font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        color: #fff;
      }
      .about_us_mission_content {
        margin-top: 10px;
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: 1.43;
        letter-spacing: normal;
        color: #f2f2f2;
      }
      .quote_img {
        width: 20px;
        height: 16px;
        position: absolute;
        right: -10px;
        top: 70px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .about_us_content {
      margin-top: 30px;
      // height: 794px;
      padding: 16px;
      margin-left: auto;
      margin-right: auto;
      .about_us_content_inner {
        border-radius: 5px;
        background-color: #fff;
        padding: 16px;
        .content_title {
          font-family: Poppins-Bold;
          font-size: 16px;
          // font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: normal;
          text-align: left;
          color: #062440;
          margin-bottom: 30px;
        }
        .content_info {
          margin-bottom: 20px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          text-align: left;
          color: #314455;
        }
        .content_info_btn {
          width: 143px;
          height: 42px;
          margin-left: auto;
          margin-right: auto;
          background-color: #1890ff;
          font-family: Poppins-Bold;
          font-size: 16px;
          // font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 42px;
          letter-spacing: normal;
          text-align: center;
          color: #fff;
          border-radius: 5px;
        }
      }
    }
    .recognised_by {
      padding-top: 15px;
      padding-bottom: 20px;
      background-color: #ffffff;
      // border: 1px solid red;
      .recognised_by_title {
        font-family: Poppins;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #67788c;
      }
      .recognised_by_content {
        // border: 1px solid red;
        height: 65px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        display: flex;
        justify-content: space-around;
		/* align-items: flex-end; */
        .recognised_by_content_item {
			margin-top: 10px;
          height: 77px;
		  .recogniseImg{
		  	height:60%;
		  }
          img {
            height: 77px;
          }
		  
        }
		
        /* .extra_padding {
          margin-left: 30px;
          margin-right: 30px;
        } */
      }
    }
    .trusted_by {
      // padding-top: 45px;
      padding-bottom: 50px;
      background-color: #ffffff;
      .trusted_by_title {
        font-family: Poppins;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #67788c;
      }
      .trusted_by_icon_content {
        // margin: 90px auto 170px;
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
        width: 322px;
        // border: 1px solid red;
        .carou_item_clients {
          height: 66px;
          display: flex;
          flex-wrap: wrap;
          .carou_item_client_logo {
            width: 70px;
            height: 30px;
            margin-right: 10px;
            margin-bottom: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            img.width_rely_img {
              width: 100%;
            }
            .height_rely_img {
              height: 100%;
            }
          }
        }
      }
    }
  }
}


.part2RecoginizedIcons{
  margin: 40px auto;
  // width: 1210px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items:center;
  gap: 30px;
}
</style>